@media (max-width: 37.4rem) {
  .TeamEditStyle_hide-sm__2kHkD {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .TeamEditStyle_visible-sm__1kGyQ {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .TeamEditStyle_hide-md__3N12I {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .TeamEditStyle_visible-md__3xU7C {
    display: none !important;
  }
}
.TeamEditStyle_user__3-ncU {
  display: flex;
  align-items: center;
}
.TeamEditStyle_userName__1KRQT {
  margin-left: 0.5rem;
}
.TeamEditStyle_formExpander__1nNWk,
.TeamEditStyle_formNonExpander__1YHXc {
  width: 100%;
  box-shadow: none!important;
  border-radius: 0!important;
  border-style: solid;
  border-width: 0.0625rem 0 0.0625rem 0;
  border-color: #cccccc;
  border-bottom: none;
}
.TeamEditStyle_formExpander__1nNWk > div:first-child,
.TeamEditStyle_formNonExpander__1YHXc > div:first-child {
  padding: 0!important;
}
.TeamEditStyle_formExpander__1nNWk > div:first-child > div:first-child,
.TeamEditStyle_formNonExpander__1YHXc > div:first-child > div:first-child {
  padding: 0.875rem 1rem!important;
}
.TeamEditStyle_formSection__2xx7c {
  width: 100%;
  box-shadow: none!important;
}
.TeamEditStyle_cardTitleContainer__2lqQ1 {
  display: flex;
  align-items: top;
  word-wrap: break-word;
}
@media (max-width: 37.4rem) {
  .TeamEditStyle_cardTitleContainer__2lqQ1 {
    display: block;
  }
}
.TeamEditStyle_cardTitle__20lK5 {
  top: 1rem;
  width: 15rem !important;
  min-width: 15rem !important;
  min-height: 1.5rem !important;
  font-family: Roboto !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  text-align: right !important;
  color: #333333 !important;
}
@media (max-width: 37.4rem) {
  .TeamEditStyle_cardTitle__20lK5 {
    text-align: left !important;
    position: static !important;
  }
}
.TeamEditStyle_cardSubTitle__3xvfD,
.TeamEditStyle_cardPlaceholderSubTitle__3qUuA,
.TeamEditStyle_cardDefaultSubTitle__3Eon9 {
  font-family: Roboto !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  text-align: left !important;
  color: #333333 !important;
  margin-left: 1.6rem !important;
  margin-right: 2rem !important;
  display: block !important;
  word-wrap: break-word;
  min-width: 50%;
}
@media (max-width: 37.4rem) {
  .TeamEditStyle_cardSubTitle__3xvfD,
  .TeamEditStyle_cardPlaceholderSubTitle__3qUuA,
  .TeamEditStyle_cardDefaultSubTitle__3Eon9 {
    margin-left: 0rem !important;
  }
}
.TeamEditStyle_cardSubTitle__3xvfD > div > p {
  margin: 0;
}
.TeamEditStyle_cardSubTitleWithOverflowMenu__vqwtS {
  margin-right: 3rem !important;
}
.TeamEditStyle_cardPlaceholderSubTitle__3qUuA {
  color: #9e9e9e !important;
}
.TeamEditStyle_cardDefaultSubTitle__3Eon9::after {
  content: " (default)";
  color: #9e9e9e !important;
}
.TeamEditStyle_cardMedia__2JNSG {
  margin-left: 17.6rem;
  margin-right: 4rem;
  margin-top: -0.5rem;
  padding-bottom: 1rem;
  max-width: 37.5rem;
}
@media (max-width: 37.4rem) {
  .TeamEditStyle_cardMedia__2JNSG {
    margin-left: 1.1rem !important;
    margin-right: 1.1rem;
  }
}
.TeamEditStyle_cardMediaFillRight__L2mrO {
  max-width: none;
}
.TeamEditStyle_cardMediaFillAll__7EQef {
  margin-left: 0;
  margin-right: 0;
}
.TeamEditStyle_cardMedia__2JNSG .TeamEditStyle_container__1LjBS {
  padding-bottom: 0.5rem !important;
}
.TeamEditStyle_cardMediaNonExpander__194pU {
  margin: 0 1.1rem;
  padding: 1rem 0;
}
.TeamEditStyle_expandLink__11N8q {
  width: 6rem;
  height: 1.19rem;
  font-family: Roboto;
  font-size: 0.8125rem;
  line-height: 1.36rem;
  color: #9e9e9e;
  margin-right: 1.5rem;
}
.TeamEditStyle_expandLink__11N8q:hover {
  color: #2f93e0;
}
.TeamEditStyle_linkContainer__3NOVC {
  width: 100%;
  text-align: right;
  padding: 0.3125rem 0;
}
.TeamEditStyle_moreInformation__2lZYt {
  padding: 1rem;
  background: rgba(153, 153, 153, 0.08);
  font-size: 0.875rem;
}
.TeamEditStyle_hide__aN1nc {
  font-family: Roboto;
  font-size: 0.75rem;
  line-height: 1.83rem;
  text-align: left;
  color: #9e9e9e;
  text-align: right;
}
.TeamEditStyle_hide__aN1nc a {
  color: #2f93e0;
}
.TeamEditStyle_sectionHeading__2bsOJ {
  width: 100%;
  background: rgba(153, 153, 153, 0.08);
  border-radius: 0!important;
  border-style: solid;
  border-width: 0.0625rem 0 0.0625rem 0;
  border-color: #cccccc;
  margin: 0 0 -0.0725rem 0;
}
.TeamEditStyle_dialogSectionHeading__1RlbS {
  background: rgba(153, 153, 153, 0.08);
  margin: 2rem -1rem 1rem;
  border-style: solid;
  border-width: 0.0625rem 0;
  border-color: #cccccc;
}
.TeamEditStyle_sectionHeadingTitle__1RwF_ {
  padding: 0.6rem 1rem;
  font-size: 1.0625rem;
}
.TeamEditStyle_overflowMenuActions__2Mc7r {
  margin-left: auto;
  align-items: center;
}
.TeamEditStyle_expandCollapse__82RhQ {
  top: 0;
  bottom: 0;
  right: 0.25rem;
  position: absolute;
}
.TeamEditStyle_noScoping__3ogyN {
  font-weight: bold;
}
.TeamEditStyle_groupImage__1BSl2 {
  width: 1.5rem;
  height: 1.5rem;
}
