.style_scopeRow__3lSQo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 1.25rem;
}
.style_scopeRow__3lSQo > * {
  margin-right: 1rem;
  max-width: 15.625rem;
  min-width: 9.375rem;
  flex: 1 1 9.375rem;
}
.style_showAll__3mAz5 {
  margin-top: 0.5rem;
}
