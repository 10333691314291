@media (max-width: 37.4rem) {
  .AuditListRow_hide-sm__23N8R {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .AuditListRow_visible-sm__2-H7I {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .AuditListRow_hide-md__2CHmD {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .AuditListRow_visible-md__Pzg0u {
    display: none !important;
  }
}
.AuditListRow_rowContainer__2XtZ8 {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.875rem;
  padding: 0.5rem 1.25rem;
  word-wrap: break-word;
}
.AuditListRow_rowContainer__2XtZ8:hover {
  background-color: rgba(153, 153, 153, 0.08) !important;
}
@media (max-width: 79.9rem) {
  .AuditListRow_rowContainer__2XtZ8 {
    flex-direction: column;
  }
}
.AuditListRow_rowContainer__2XtZ8 .AuditListRow_whenContainer__O54y9 {
  width: 11rem;
  min-width: 11rem;
  white-space: nowrap;
  margin-right: 0.5rem;
}
.AuditListRow_rowContainer__2XtZ8 .AuditListRow_whoContainer__1qlo9 {
  width: 16rem;
  min-width: 16rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.5rem;
}
.AuditListRow_rowContainer__2XtZ8 .AuditListRow_whoContainer__1qlo9 .AuditListRow_usernameText__E_jOT {
  margin: 0 0.25rem;
  max-width: 14rem;
  line-height: 1rem;
}
@media (max-width: 79.9rem) {
  .AuditListRow_rowContainer__2XtZ8 .AuditListRow_whoContainer__1qlo9 .AuditListRow_usernameText__E_jOT {
    width: unset;
  }
}
.AuditListRow_detailsContainer__1Op-L {
  font-size: 0.875rem;
  padding: 0.25rem 1.25rem;
}
.AuditListRow_keyValueGrid__3qS-l {
  display: flex;
  flex-direction: column;
  padding: 0.75rem 0;
}
.AuditListRow_keyValueGrid__3qS-l .AuditListRow_row__2tkbk {
  display: flex;
  flex-direction: row;
}
.AuditListRow_keyValueGrid__3qS-l .AuditListRow_row__2tkbk .AuditListRow_key__1Rti6 {
  flex-basis: 13%;
  text-align: left;
  margin-right: 1.25rem;
  font-weight: 600;
}
.AuditListRow_userAgentTooltipContainer__UXJOk {
  text-align: left;
}
