.style_markdownNote__19KKy {
  font-family: Roboto;
  font-size: 0.75rem;
  text-align: left;
  font-weight: 300;
  margin-bottom: 0.5rem;
  color: #666666;
}
.style_markdownNote__19KKy div {
  font-size: 0.75rem;
}
.style_markdownDescriptionContainer__DXs8I div {
  padding-top: 0.125rem;
}
.style_markdownDescriptionContainer__DXs8I div p {
  margin: 0px;
}
