.style_markdown__2nUCz {
  line-height: normal;
}
.style_markdown__2nUCz ul {
  list-style-type: disc;
  padding-left: 2.5rem;
}
.style_markdown__2nUCz ol {
  list-style-type: decimal;
  padding-left: 2.5rem;
}
.style_markdown__2nUCz blockquote {
  border-left: 0.25rem solid #cccccc;
  padding-left: 1rem;
}
