@media (max-width: 37.4rem) {
  .style_hide-sm__2KWi3 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__3pQCU {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__1er8O {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__3ST-P {
    display: none !important;
  }
}
.style_container__3mqQL {
  display: flex;
  flex-direction: column;
}
.style_onboardingPanelOuterContainer__2dq2N {
  display: flex;
  align-items: center;
  justify-content: center;
}
.style_onboardingPanelInnerContainer__CjVca {
  max-width: 37.5rem;
  background-color: #ffffff;
  margin-top: 3rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.0625rem 0.25rem 0 rgba(0, 0, 0, 0.12);
}
@media (max-width: 37.4rem) {
  .style_onboardingPanelInnerContainer__CjVca {
    margin-top: 0.25rem;
  }
}
.style_paper__1xYXs div:first-child {
  max-width: none;
}
.style_filterHeaderContainer__BE8CX {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1 0 auto;
  width: 100%;
}
@media (max-width: 37.4rem) {
  .style_filterHeaderContainer__BE8CX {
    flex-direction: column;
    align-items: flex-start;
  }
}
.style_filterFieldContainer__2M0fz {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex: 1 0 auto;
  width: 100%;
  justify-content: flex-start;
}
@media (max-width: 37.4rem) {
  .style_filterFieldContainer__2M0fz {
    flex-direction: column;
    align-items: flex-start;
  }
}
.style_filter__33FNi {
  align-items: flex-end;
  flex: 1 0 auto;
}
@media (max-width: 37.4rem) {
  .style_filter__33FNi {
    flex-direction: column;
  }
}
.style_filterField___JN5V {
  width: 100%;
  max-width: 24rem!important;
}
.style_filterField___JN5V > div {
  width: 100%;
}
.style_matchCount__2mtCJ {
  margin-bottom: 0.75rem;
  font-size: 0.875rem;
}
.style_alert__dCU50 {
  color: #fb8c00;
}
.style_info__1aeBv {
  color: #0878D1;
}
