@media (max-width: 37.4rem) {
  .style_hide-sm__11k38 {
    display: none !important;
  }
}
@media (min-width: 37.4rem) {
  .style_visible-sm__12vNS {
    display: none !important;
  }
}
@media (max-width: 79.9rem) {
  .style_hide-md__3k80t {
    display: none !important;
  }
}
@media (min-width: 79.9rem) {
  .style_visible-md__P0406 {
    display: none !important;
  }
}
.style_warningFormExpander__31SCm {
  width: 100%;
  box-shadow: none!important;
  border-radius: 0!important;
  border-style: solid;
  border-width: 0.0625rem 0 0.0625rem 0;
  border-color: #cccccc;
  border-bottom: none;
}
.style_warningFormExpander__31SCm > div:first-child {
  padding: 0!important;
}
.style_formBorder__2eMK6 {
  border-radius: 0!important;
  border-style: solid;
  border-width: 0.0625rem 0 0.0625rem 0;
  border-color: #cccccc;
}
.style_formExpander__2IeU7,
.style_formNonExpander__1pFww {
  width: 100%;
  box-shadow: none!important;
  border-radius: 0!important;
  border-style: solid;
  border-width: 0.0625rem 0 0.0625rem 0;
  border-color: #cccccc;
  border-bottom: none;
}
.style_formSection__mA4eR {
  width: 100%;
  box-shadow: none!important;
}
.style_cardTitleContainer__31DfR {
  display: flex;
  align-items: top;
  word-wrap: break-word;
  padding: 0.875rem 1rem;
  font-size: 1.5rem;
  color: rgba(51, 51, 51, 0.87) !important;
  line-height: 2.25;
  position: relative;
  flex-direction: row;
}
@media (max-width: 37.4rem) {
  .style_cardTitleContainer__31DfR {
    flex-direction: column;
  }
}
.style_expandableFormTitleContainer__2ZnuB {
  display: flex;
  align-items: top;
  word-wrap: break-word;
  padding: 0.875rem 1rem;
  font-size: 1.5rem;
  color: rgba(51, 51, 51, 0.87) !important;
  line-height: 2.25;
  position: relative;
  flex-direction: row;
  cursor: pointer;
}
@media (max-width: 37.4rem) {
  .style_expandableFormTitleContainer__2ZnuB {
    flex-direction: column;
  }
}
.style_cardTitleContainerForceMobile__3316e {
  display: flex;
  align-items: top;
  word-wrap: break-word;
  padding: 0.875rem 1rem;
  font-size: 1.5rem;
  color: rgba(51, 51, 51, 0.87) !important;
  line-height: 2.25;
  position: relative;
  flex-direction: row;
  display: block;
  padding: 0rem 1rem 1rem;
}
@media (max-width: 37.4rem) {
  .style_cardTitleContainerForceMobile__3316e {
    flex-direction: column;
  }
}
.style_cardTitle__2-dRX {
  top: 1rem;
  width: 15rem !important;
  min-width: 15rem !important;
  min-height: 1.5rem !important;
  line-height: 1.5rem !important;
  font-family: Roboto !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  text-align: right !important;
  color: #333333 !important;
}
@media (max-width: 37.4rem) {
  .style_cardTitle__2-dRX {
    text-align: left !important;
    position: static !important;
  }
}
.style_cardTitleForceMobile__3V-u5 {
  top: 1rem;
  width: 15rem !important;
  min-width: 15rem !important;
  min-height: 1.5rem !important;
  line-height: 1.5rem !important;
  font-family: Roboto !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  text-align: right !important;
  color: #333333 !important;
  text-align: left !important;
  position: static !important;
}
@media (max-width: 37.4rem) {
  .style_cardTitleForceMobile__3V-u5 {
    text-align: left !important;
    position: static !important;
  }
}
.style_cardSubTitle__3CRft,
.style_cardPlaceholderSubTitle__1-Af6,
.style_cardDefaultSubTitle__VMnRY {
  font-family: Roboto !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  text-align: left !important;
  color: #333333 !important;
  margin-left: 1.6rem !important;
  margin-right: 2rem !important;
  display: block !important;
  word-break: break-word;
  min-width: 50%;
}
@media (max-width: 37.4rem) {
  .style_cardSubTitle__3CRft,
  .style_cardPlaceholderSubTitle__1-Af6,
  .style_cardDefaultSubTitle__VMnRY {
    margin-left: 0rem !important;
  }
}
.style_cardSubTitle__3CRft > div > p {
  margin: 0;
}
.style_cardSubTitleForceMobile__3K_y3 {
  font-family: Roboto !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  text-align: left !important;
  color: #333333 !important;
  margin-left: 1.6rem !important;
  margin-right: 2rem !important;
  display: block !important;
  word-break: break-word;
  min-width: 50%;
  margin-left: 0rem !important;
}
@media (max-width: 37.4rem) {
  .style_cardSubTitleForceMobile__3K_y3 {
    margin-left: 0rem !important;
  }
}
.style_cardSubTitleForceMobile__3K_y3 > div > p {
  margin: 0;
}
.style_cardSubTitleWithOverflowMenu__t-38H {
  margin-right: 3rem !important;
}
.style_cardPlaceholderSubTitle__1-Af6 {
  color: #9e9e9e !important;
}
.style_cardDefaultSubTitle__VMnRY::after {
  content: " (default)";
  color: #9e9e9e !important;
}
.style_cardMedia__3hGrP {
  margin-left: 17.6rem;
  margin-right: 4rem;
  margin-top: -0.5rem;
  padding: 0 0 1rem 0 !important;
  max-width: 37.5rem;
}
@media (max-width: 37.4rem) {
  .style_cardMedia__3hGrP {
    margin-left: 1.1rem !important;
    margin-right: 1.1rem;
  }
}
.style_cardMediaForceMobile__2pdbM {
  margin-left: 17.6rem;
  margin-right: 4rem;
  margin-top: -0.5rem;
  padding: 0 0 1rem 0 !important;
  max-width: 37.5rem;
  margin-left: 1.1rem !important;
  margin-right: 1.1rem;
}
@media (max-width: 37.4rem) {
  .style_cardMediaForceMobile__2pdbM {
    margin-left: 1.1rem !important;
    margin-right: 1.1rem;
  }
}
.style_cardMediaFillRight__2FeTa {
  max-width: none;
}
.style_cardMediaFillAll__2xCDm {
  margin-left: 0;
  margin-right: 0;
}
.style_cardMedia__3hGrP .style_container__68vYY {
  padding-bottom: 0.5rem !important;
}
.style_unstructuredFormContent__1WWYt {
  padding: 1rem !important;
}
.style_expandLink__1iKhk {
  width: 6rem;
  height: 1.19rem;
  font-family: Roboto;
  font-size: 0.8125rem;
  line-height: 1.36rem;
  color: #9e9e9e;
  margin-right: 1.5rem;
}
.style_expandLink__1iKhk:hover {
  color: #2f93e0;
}
.style_linkContainer__2iXlo {
  width: 100%;
  text-align: right;
  padding: 0.3125rem 0;
}
.style_moreInformation__1USFP {
  padding: 1rem;
  background: rgba(153, 153, 153, 0.08);
  font-size: 0.875rem;
}
.style_hide__2lPVB {
  font-family: Roboto;
  font-size: 0.75rem;
  line-height: 1.83rem;
  text-align: left;
  color: #9e9e9e;
  text-align: right;
}
.style_hide__2lPVB a {
  color: #2f93e0;
}
.style_sectionHeading__yTuIc {
  width: 100%;
  background: rgba(153, 153, 153, 0.08);
  border-radius: 0!important;
  border-style: solid;
  border-width: 0.0625rem 0 0.0625rem 0;
  border-color: #cccccc;
  margin: 0 0 -0.0725rem 0;
}
.style_dialogSectionHeading__2oJ0Q {
  background: rgba(153, 153, 153, 0.08);
  margin: 2rem -1rem 1rem;
  border-style: solid;
  border-width: 0.0625rem 0;
  border-color: #cccccc;
}
.style_sectionHeadingTitle__IWOHy {
  padding: 0.6rem 1rem;
  font-size: 1.0625rem;
}
.style_overflowMenuActions__2eyWM {
  margin-left: auto;
  padding-right: 2.25rem;
  align-items: center;
}
.style_expandIcon__3KJZE,
.style_expandIcon__3KJZE:hover {
  right: 0.25rem!important;
  position: absolute!important;
  background-color: transparent!important;
  top: 0;
  bottom: 0;
}
.style_expandClosed__1lESO {
  transform: rotate(0deg) !important;
}
.style_expandOpen__3i01A {
  transform: rotate(180deg) !important;
}
.style_stretchContent__2xspE {
  padding: 0!important;
  margin: 0!important;
}
